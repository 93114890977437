<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <sb-card :loading="$wait.is('taskLoading')">
          <template slot="title">
            <v-card-title>
              <sb-title title="Capture Authorisation" />
              <v-spacer />
              <v-row v-if="audit.updatedBy"
                     align="center"
                     justify="end">
                <v-tooltip v-if="!$wait.any"
                           left>
                  <template #activator="{on}">
                    <span class="text-body-2 pr-5"
                          v-on="on">
                      <v-icon left>mdi-account-clock</v-icon>
                      {{ audit.updatedBy | startCase }}
                    </span>
                  </template>
                  <span>Last Updated by {{ audit.updatedBy | startCase }} on {{ audit.updatedTimestamp }}</span>
                </v-tooltip>
              </v-row>
            </v-card-title>
          </template>

          <v-container :class="{'disable-pointer': disabled}">
            <v-row>
              <v-col cols="12"
                     sm="6"
                     class="pt-0 pb-5 mb-5">
                <sb-checkbox v-model="managedAuthorisation.authType.isFms"
                             label="FMS Authorisation"
                             hide-details />
              </v-col>
              <v-col cols="12"
                     sm="6"
                     class="pt-0 pb-5 mb-5">
                <sb-checkbox v-model="managedAuthorisation.authType.isClient"
                             label="Client Authorisation"
                             hide-details />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="6"
                     class="py-0">
                <sb-text-field v-model="managedAuthorisation.authorisationNumber"
                               label="Authorisation Number" />
              </v-col>
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-date-picker-field v-model="managedAuthorisation.authorisationDate"
                                      :max="maxAuthDate"
                                      label="Authorisation Date"
                                      ref-name="authorisationDate" />
              </v-col>
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-time-picker v-model="managedAuthorisation.authorisationTime"
                                :max="maxAuthTime"
                                label="Authorisation Time"
                                ref-name="authorisationTime" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" />
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-date-picker-field v-model="managedAuthorisation.estimateRepairDate"
                                      :disabled="estimateRepairDisabled"
                                      :persistent-hint="true"
                                      :hint="estimateRepairDisabled ? 'Authorisation details required' : ''"
                                      :min="managedAuthorisation.authorisationDate"
                                      label="Estimate Repair Date"
                                      ref-name="estimateRepairDate" />
              </v-col>
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-time-picker v-model="managedAuthorisation.estimateRepairTime"
                                :disabled="estimateRepairDisabled"
                                :min="minEstRepairTime"
                                label="Estimate Repair Time"
                                ref-name="estimateRepairTime" />
              </v-col>
            </v-row>
          </v-container>
          <sb-title title="Collection" />
          <v-container :class="{'disable-pointer': disabled}">
            <v-row>
              <v-col cols="12"
                     sm="6"
                     class="py-0">
                <sb-text-field v-model="managedAuthorisation.collectedBy"
                               :disabled="collectionDetailsDisabled"
                               :persistent-hint="true"
                               :hint="collectionDetailsDisabled ? 'Estimate details required' : ''"
                               label="Collected By" />
              </v-col>
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-date-picker-field v-model="managedAuthorisation.collectedDate"
                                      :disabled="collectionDetailsDisabled"
                                      :persistent-hint="true"
                                      :hint="collectionDetailsDisabled ? 'Estimate details required' : ''"
                                      :min="managedAuthorisation.estimateRepairDate"
                                      label="Collected Date"
                                      ref-name="collectedDate" />
              </v-col>
              <v-col cols="12"
                     sm="3"
                     class="py-0">
                <sb-time-picker v-model="managedAuthorisation.collectedTime"
                                :disabled="collectionDetailsDisabled"
                                :min="minCollectedTime"
                                label="Collected Time"
                                ref-name="collectedTime" />
              </v-col>
              <v-col cols="12"
                     class="py-0">
                <sb-checkbox v-model="managedAuthorisation.isVehicleOnRoad"
                             :disabled="vehicleOnroadDisabled"
                             custom-class="disable-pointer-events"
                             hide-details>
                  <template #label>
                    Is the Vehicle on road again
                    <v-tooltip v-if="vehicleOnroadDisabled"
                               bottom>
                      <template #activator="{on}">
                        <v-icon right
                                v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>Collection details required to be able to mark the vehicle back on road</span>
                    </v-tooltip>
                  </template>
                </sb-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <sb-title title="Documents"
                    class="mt-5" />

          <sb-attachments v-model="managedAuthorisation.documents"
                          :disabled="disabled"
                          :service-request-id="serviceRequestNumber" />

          <template v-if="!disabled"
                    slot="actions">
            <v-spacer />
            <v-slide-x-reverse-transition>
              <v-btn v-if="showButtons"
                     color="success"
                     outlined
                     large
                     :loading="$wait.is('saveServiceRequestLoading')"
                     :disabled="$wait.any"
                     @click="$emit('click-save')">
                Save
              </v-btn>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition>
              <v-btn v-if="showButtons"
                     color="secondary"
                     large
                     depressed
                     :loading="$wait.is('completeServiceRequestLoading')"
                     :disabled="$wait.any || disabledComplete || !isVehicleOnRoad"
                     @click="$emit('click-complete')">
                Complete Service Request
              </v-btn>
            </v-slide-x-reverse-transition>
          </template>
        </sb-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

import { AUTHORISATION_TYPES, SERVICE_TYPE_LIST } from '@/config/constants';

import SbAttachments from '@/components/core/SbAttachments';

import ManagedAuthortisation from '@/models/ManagedAuthorisation';
import Audit from '@/models/Audit';

export default {
  components: {
    SbAttachments,
  },

  props: {
    value: {
      type: Object,
      default: () => new ManagedAuthortisation(),
    },
    audit: {
      type: Object,
      default: () => new Audit(),
    },
    disabledComplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    serviceRequestNumber: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: [],
      showButtons: false,
      maxAuthDate: moment().format('YYYY-MM-DD'),
      maxAuthTime: undefined,
      minEstRepairTime: undefined,
      minCollectedTime: undefined,
      fileTypes: 'image/*,.pdf',
      rules: [(value) => !value || value.size < 5120000 || 'Document size should be less than 5 MB!'],
      managedAuthorisation: new ManagedAuthortisation(),
    };
  },

  computed: {
    authorisationTypes() {
      return Object.values(AUTHORISATION_TYPES);
    },

    selectedAuthDate() {
      return this.managedAuthorisation.authorisationDate;
    },

    selectedAuthTime() {
      return this.managedAuthorisation.authorisationTime;
    },

    selectedEstimateRepairDate() {
      return this.managedAuthorisation.estimateRepairDate;
    },

    selectedEstimateRepairTime() {
      return this.managedAuthorisation.estimateRepairTime;
    },

    selectedCollectedDate() {
      return this.managedAuthorisation.collectedDate;
    },

    estimateRepairDisabled() {
      return (
        !this.managedAuthorisation.authorisationNumber || !this.managedAuthorisation.authorisationDate || !this.managedAuthorisation.authorisationTime
      );
    },

    collectionDetailsDisabled() {
      return !this.managedAuthorisation.estimateRepairDate || !this.managedAuthorisation.estimateRepairTime;
    },

    vehicleOnroadDisabled() {
      return !this.managedAuthorisation.collectedBy || !this.managedAuthorisation.collectedDate || !this.managedAuthorisation.collectedTime;
    },

    isManagedAuthorisation() {
      return this.serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key;
    },

    isVehicleOnRoad() {
      return this.managedAuthorisation.isVehicleOnRoad;
    },
  },

  watch: {
    // Handles internal model changes.
    managedAuthorisation(newVal) {
      this.$emit('input', newVal);
    },

    // Handles external model changes.
    value(newVal) {
      this.managedAuthorisation = newVal;
    },

    estimateRepairDisabled(disabled) {
      if (disabled) {
        this.managedAuthorisation.estimateRepairDate = null;
        this.managedAuthorisation.estimateRepairTime = null;
      }
    },

    collectionDetailsDisabled(disabled) {
      if (disabled) {
        this.managedAuthorisation.collectedBy = null;
        this.managedAuthorisation.collectedDate = null;
        this.managedAuthorisation.collectedTime = null;
      }
    },

    vehicleOnroadDisabled(disabled) {
      if (disabled) {
        this.managedAuthorisation.isVehicleOnRoad = false;
      }
    },

    selectedAuthDate() {
      const currentTime = moment().format('HH:mm');

      // Reset Time when Date changes
      this.managedAuthorisation.authorisationTime = '';

      // Check whether the date selected is today
      if (this.managedAuthorisation.authorisationDate === this.maxAuthDate) {
        // Restrict time selection to current time and set time to current time
        this.maxAuthTime = currentTime;
        this.managedAuthorisation.authorisationTime = currentTime;
      } else {
        // Remove restriction if date is not today
        this.maxAuthTime = null;
      }
    },

    selectedEstimateRepairDate() {
      // Reset Time when Date changes
      this.managedAuthorisation.estimateRepairTime = '';
      // Check whether the date selected is the same as auth date
      if (this.managedAuthorisation.estimateRepairDate === this.managedAuthorisation.authorisationDate) {
        // Restrict time selection to current time and set time to current time
        this.minEstRepairTime = this.managedAuthorisation.authorisationTime;
        this.managedAuthorisation.estimateRepairTime = this.managedAuthorisation.authorisationTime;
      } else {
        // Remove restriction if date is not today
        this.minEstRepairTime = null;
      }
    },

    selectedCollectedDate() {
      // Reset Time when Date changes
      this.managedAuthorisation.collectedTime = '';
      // Check whether the date selected is the same as repair date
      if (this.managedAuthorisation.collectedDate === this.managedAuthorisation.estimateRepairDate) {
        // Restrict time selection to current time and set time to current time
        this.minCollectedTime = this.managedAuthorisation.estimateRepairTime;
        this.managedAuthorisation.collectedTime = this.managedAuthorisation.estimateRepairTime;
      } else {
        // Remove restriction if date is not today
        this.minCollectedTime = null;
      }
    },

    selectedAuthTime() {
      this.managedAuthorisation.estimateRepairDate = null;
      this.managedAuthorisation.estimateRepairTime = null;
      this.managedAuthorisation.collectedDate = null;
      this.managedAuthorisation.collectedTime = null;
    },

    selectedEstimateRepairTime() {
      this.managedAuthorisation.collectedDate = null;
      this.managedAuthorisation.collectedTime = null;
    },
  },

  created() {
    if (this.value) {
      this.managedAuthorisation = new ManagedAuthortisation(this.value);
    }
  },

  mounted() {
    setTimeout(() => {
      this.showButtons = true;
    }, 800);
  },
};
</script>
