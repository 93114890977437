<template>
  <v-container>
    <sb-attachment-upload :disabled="disabled"
                          :default-file-net-file-type="defaultFileNetFileType"
                          :hide-file-net-file-type="hideFileNetFileType"
                          :service-request-id="serviceRequestId" />
    <sb-document-list :attachments="attachments"
                      :is-loading="$wait.is('getAllAttachmentsLoading')"
                      :is-downloading="$wait.is('getAttachmentLoading')"
                      @download-document="downloadAttachment" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { EventBus } from '@/utils/event-bus';
import SbDocumentList from '@/components/core/SbDocumentList';
import SbAttachmentUpload from '@/components/core/SbAttachmentUpload';

export default {
  components: {
    SbDocumentList,
    SbAttachmentUpload,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultFileNetFileType: {
      type: String,
      default: '',
    },
    hideFileNetFileType: {
      type: Boolean,
      default: false,
    },
    serviceRequestId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedFileNetDocType: this.defaultFileNetFileType,
      attachments: [],
    };
  },

  watch: {
    serviceRequestId: {
      handler(value) {
        if (value) {
          this.getAttachments();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    EventBus.$on('upload-completed', async () => {
      await this.getAttachments();
    });
  },
  methods: {
    ...mapActions('attachments', ['getAttachment', 'getAllAttachments']),

    ...mapWaitingActions('attachments', {
      getAllAttachments: 'getAllAttachmentsLoading',
      getAttachment: 'getAttachmentLoading',
    }),

    async downloadAttachment(attachmentId) {
      await this.getAttachment(attachmentId);
    },

    async getAttachments() {
      const payload = { serviceRequestId: this.serviceRequestId, fileType: this.selectedFileNetDocType };
      this.getAllAttachments(payload).then((response) => {
        if (!response.status) {
          this.attachments = response.documents;
        }
      });
    },
  },
};
</script>
